import Vue from 'vue';
import Vuex from 'vuex';
import BSCReader from './modules/BSCReader';
import Contracts from './modules/Contracts';
import Events from './modules/Events';
import Forms from './modules/Forms';
import Models from './modules/Models';
import Pairs from './modules/Pairs';
import Session from './modules/Session';
import Wallet from './modules/Wallet';
import Web3 from './modules/Web3';

Vue.use(Vuex);
const debug = true;

export const store = new Vuex.Store({
    modules: {
        BSCReader: BSCReader,
        contracts: Contracts,
        models: Models,
        events: Events,
        forms: Forms,
        pairs: Pairs,
        session: Session,
        wallet: Wallet,
        web3: Web3,
    },
    strict: debug
});