<template>
	<div class="app" v-if="!appOptions.appEmpty" v-bind:class="{
		'app-header-fixed': appOptions.appHeaderFixed && !appOptions.appHeaderNone,
		'app-sidebar-fixed': appOptions.appSidebarFixed,
		'app-sidebar-minified': appOptions.appSidebarMinified, 
		'app-content-full-height': appOptions.appContentFullHeight, 
		'app-without-sidebar': appOptions.appSidebarNone, 
		'app-with-end-sidebar': appOptions.appSidebarEnd, 
		'app-with-two-sidebar': appOptions.appSidebarTwo,
		'app-with-wide-sidebar': appOptions.appSidebarWide,
		'app-with-light-sidebar': appOptions.appSidebarLight,
		'app-sidebar-mobile-toggled': appOptions.appSidebarMobileToggled,
		'app-sidebar-end-toggled': appOptions.appSidebarEndToggled,
		'app-sidebar-end-collapsed': !appOptions.appSidebarEndToggled,
		'app-sidebar-end-mobile-toggled': appOptions.appSidebarEndMobileToggled,
		'app-without-header': appOptions.appHeaderNone,
		'app-with-top-menu': appOptions.appTopMenu,
		'app-gradient-enabled': appOptions.appGradientEnabled,
		'has-scroll': appOptions.appBodyScrollTop
	}">
		<Header />
		<TopMenu v-if="appOptions.appTopMenu" />
		<Sidebar v-if="!appOptions.appSidebarNone" />
		<SidebarRight v-if="appOptions.appSidebarTwo" />
		<div class="app-cover"></div>
        <div style=""></div>

        

		<div id="content" class="app-content" v-bind:class="appOptions.appContentClass">
			<ConnectYourWallet v-if="$store.state.web3.networkId === null"></ConnectYourWallet>
			<router-view v-bind:key="$store.state.web3.networkId" v-if="$store.state.web3.networkId !== null && networkCompatible"></router-view>
			<NetworkLookUp v-if="$store.state.web3.networkId !== null && !networkCompatible"></NetworkLookUp>
			<vue-ins-progress-bar></vue-ins-progress-bar>
		</div>
		<SidebarRight v-if="appOptions.appSidebarTwo" />
		<ScrollTopBtn />
        <!-- #modal-dialog -->
        <div class="modal fade bg-gray-200 bg-opacity-20" id="walletConnectModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content bg-gray-900">
                    <div class="modal-header">
                        <h4 class="modal-title">Connect your wallet</h4>
                        <button id="walletConnectModalClose" type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <button type="button" class="btn btn-grad w-100" v-on:click="connectWallet('metamask')">Metamask</button>
                            </div>
                            <div class="col-12 col-lg-6">
                                <button type="button" class="btn btn-grad w-100" v-on:click="connectWallet('walletConnect')">Wallet Connect</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="d-none" id="toggleDisclaimerModal" data-bs-toggle="modal" data-bs-target="#disclaimerModal"></button>
        <!-- #modal-dialog -->
        <div class="modal fade bg-gray-200 bg-opacity-20" id="disclaimerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content bg-gray-900">
                    <div class="modal-header">
                        <h4 class="modal-title text-white">Disclaimer</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col-12 text-white">
                                <p class="text-justify">
                                    UNOFFICIAL dApp<br>
                                    This dApp have been created and is maintened by BOT Morino. In any way it is affiliated to City of Atlantus other than the fact that BOT Morino is an investors.
                                </p>
                                <p class="text-justify">
                                    The information contained in this website is for general information purposes only. The information is provided by Cointools.me and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
                                </p>
                                <p class="text-justify">
                                    In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
                                </p>
                                <p class="text-justify">
                                    Through this website you are able to link to other websites which are not under the control of Cointools.me. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
                                </p>
                                <p class="text-justify">
                                    Every effort is made to keep the website up and running smoothly. However, Cointools.me takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-grad" v-on:click="acceptDisclaimer">I accept</button>
                    </div>
                </div>
            </div>
        </div>
	</div>
	<div class="h-100" v-else>
		<router-view></router-view>
		<vue-ins-progress-bar></vue-ins-progress-bar>
	</div>
    
</template>

<script>
import {version} from '../package';
import Sidebar from './components/sidebar/Sidebar.vue';
import SidebarRight from './components/sidebar-right/SidebarRight.vue';
import Header from './components/header/Header.vue';
import TopMenu from './components/top-menu/TopMenu.vue';
import ScrollTopBtn from './components/scroll-top-btn/ScrollTopBtn.vue';
import AppOptions from './config/AppOptions.vue';
import AppRoutes from './config/AppRoutes.vue';
import NetworkLookUp from './components/utilities/NetworkLookUp.vue';
import ConnectYourWallet from './components/utilities/ConnectYourWallet.vue';

import appOptions from './config/Options';
import sidebarMenu from './config/SideBar';
import requestsConditions from './config/RequestsConditions';
import { mapState } from 'vuex';

export default {
	name: 'app',
	components: {
		Sidebar,
		SidebarRight,
		Header,
		TopMenu,
		ScrollTopBtn,
        NetworkLookUp,
        ConnectYourWallet
	},

	data() {
		return {
			appOptions: AppOptions,
            appRoutes: AppRoutes,
			//System
			displayDecimals: 3,
			config: {
				appOptions: appOptions,
				sidebarMenu: sidebarMenu,
			},
			requestsConditions: requestsConditions,
			requestsTries: {},
            counter: 0,
            version: version,
            networkCompatible: false,
		}
	},

	created() {
        // this.$store.dispatch('BSCReader/initBSCReader');

		this.getSession();

		AppOptions.appBodyScrollTop = window.scrollY;
		
		window.addEventListener('scroll', this.handleScroll);
		
		this.$insProgress.start();
		
		this.$router.beforeEach((to, from, next) => {
			this.$insProgress.start();
			next();
		});
		this.$router.afterEach(() => {
			this.$insProgress.finish();
			AppOptions.appSidebarMobileToggled = false;
		});

        // this.$store.subscribeAction((action, state) => {
        //     if (action.type.split('/')[0] !== 'events') {return;}
        //     this.$eventBus.$emit(action.type, {payload: action.payload});
        // });
        this.$store.subscribeAction({
            after: (action, state) => {
                // if (action.type.split('/')[0] !== 'events') {return;}
                this.$eventBus.$emit(action.type, {payload: action.payload});
                if (action.type.split('/')[1] === "getNetworkId") {this.checkNetworkCompatibility();}
                // if (action.type === "BSCReader/initBSCReader") {this.$store.dispatch('BSCReader/loadRequiredContracts');}
            }
        });
	},

    watch: {
        "$route": function(to, from) {this.$eventBus.$emit('routeChanged');this.checkNetworkCompatibility()},

        "session.isConnected": function(newVal, oldVal) {
            if (oldVal !== null) {this.putSession();}
            if (oldVal === null && newVal === true && this.wallet.account === undefined) {this.verifyRequestConditionsAndContinueOrDelay('getWalletAccount');} //In case the website authorisation got revoke and the session tell it's connected, reset state.
        },

        "web3.isInitialised": function() {
            this.verifyRequestConditionsAndContinueOrDelay('getWalletAccount');
        },
    },

    computed: mapState(['contracts', 'pairs', 'session', 'wallet', 'web3']),

	methods: {
		handleScroll: function() {
			AppOptions.appBodyScrollTop = window.scrollY;
		},

        checkNetworkCompatibility: function() {
            const index = this.appRoutes.findIndex(route => route.path === this.$route.matched[0].path);
            this.networkCompatible = this.appRoutes[index].networks.includes(this.$store.state.web3.networkId);
        },

        // // [START] - Request Condition
        // // This is a helper that verify if a function can be executed, if not, it's delayed further in the stack.
        verifyRequestConditionsAndContinueOrDelay: function(request, payload = false) {
            if (this.requestsTries[request] === undefined) {this.requestsTries[request] = 0;}
            // console.log(request+' || tries: '+this.requestsTries[request]);
            if (this.verifyGlobalConditions() && this.verifyRequestConditions(request, payload)) {
                this.requestsTries[request] = 0;
                this.$nextTick(function() {
                    this[request](payload);
                });
                return;
            }
            if (this.requestsTries[request] < this.requestsConditions.config.tries) {
                let self = this;
                setTimeout(function() {
                    self.verifyRequestConditionsAndContinueOrDelay(request, payload)
                }, this.requestsConditions.config.timer);
                this.requestsTries[request]++;
                return;
            }
            console.log('This request have been stop: '+request);
            console.log(payload);
            this.requestsTries[request] = 0;
        },

        verifyGlobalConditions: function() {
            return true;
        },

        verifyRequestConditions: function(request, payload) {
            if (this.requestsConditions.requests[request] === undefined) {return true;}
            for (var condition of this.requestsConditions.requests[request].conditions) {
                if (condition.type === 'equal' && this[condition.module][condition.state] !== condition.value) {return false;}
                if (condition.type === 'notEqual' && this[condition.module][condition.state] === condition.value) {return false;}
                if (condition.type === 'getters' && this.$store.getters[condition.module+'/'+condition.state](payload) !== condition.value) {return false;}
                if (condition.type === 'gettersRoot' && this.$store.getters[condition.module+'/'+condition.state](this.$store.state.contracts[payload].root) !== condition.value) {return false;}
            }
            return true;
        },
        // // [END] - Request Condition

        // [START] - Session Handler
        // This part is used to sync the wallet connection. The wallet connection is responsible to either show or not the data.

        // getSession : The purpose of this funtion is too retrieve the value of the wallet connection from the sesssion store onto the back send server.
        getSession: async function() {
            // var response = await this.$api.getFunction('/session/get'); //Get the current state of session isConnected
            // if (response.data === 1) {response.data = true;} else {response.data = false;} //Transform server response to fit Javascript
            // this.$store.commit('session/setConnection', response.data); //Store the response inside the store session state.
            // if (this.session.isInitialised === false) {this.$store.commit('session/setInitialisation');} //Tell the app that the session has been initialised.
            this.$store.commit('session/setConnection', true);
            if (this.session.isInitialised === false) {this.$store.commit('session/setInitialisation');}
        },

        // putSession : The purpose of this function is to send value of the wallet connection to back end server and store it into the session of the user.
        putSession: function() {
            // this.$api.putFunction('/session/put', {isConnected: this.session.isConnected}); //Put the current state of the session isConnected (is the wallet connected) into the server.
        },
        // [END] - Session Handler

        getWalletAccount: async function(firstOccurence = false) {
            await this.$store.dispatch('wallet/getAccount');
            if (this.wallet.account !== undefined && firstOccurence === false) {
                this.$eventBus.$emit('walletAccountIsSet');
                return;
            }
            if (this.wallet.account === undefined && firstOccurence === true) { //The first occurence is forced if the session connection was set to true. This condition will be filled if the web3 authorization was revoked.
                this.$store.commit('session/setConnection', false); //Force session to not connect, because authorization was revoked.
            }
        },

        connectWallet: async function(providerName) {
            // this.$store.dispatch('web3/initialise', providerName);
            await this.$store.dispatch('web3/initialise', providerName);
            await this.getWalletAccount();
            this.$store.commit('session/setConnection', true);
            document.getElementById('walletConnectModalClose').click();
        },

        switchNetwork: async function(network) {
            console.log("DEBUG: DISPATCH MESSAGE FOR SWITCH NETWORK");
            console.log(network);
            await this.$store.dispatch('web3/switchNetwork', network);
            this.checkNetworkCompatibility();
        },

        disconnectWallet: async function() {
            this.$store.commit('session/setConnection', false);
            this.$store.dispatch('web3/disconnectWallet');
            // this.resetDatas();
        },

        getRequiredContracts: async function(requiredContracts) {//This need to be seperate getRequiredContracts and getRequiredBalances...
            if (this.session.isConnected === false) {return;}
            for(var contract of requiredContracts) {
                this.$store.dispatch('contracts/instantiateContract', contract);
            }
        },

        getWalletBalance: async function(contract) {
            this.checkNetworkCompatibility();
            this.$store.dispatch('wallet/getWalletBalance', contract);
        },

        getRequiredPairs: async function(requiredPairs) {
            // if (this.session.isConnected === false) {return;}
            // for(var pair of requiredPairs) {
            //     this.$store.dispatch('pairs/instantiatePair', pair);
            // }
        },

        getRequiredPools: async function(requiredPools) {
            if (this.session.isConnected === false) {return;}
            for(var pool of requiredPools) {
                this.$store.dispatch('pools/instantiatePool', pool);
            }
        },

        callAllowanceAmount: async function(contract) {
            var response = await this.$calls.allowance(this.$store.state.wallet.account, false, this.$store.state.contracts[contract].address, this.$store.state.contracts[this.$store.state.contracts[contract].root]);
            if (response === undefined) {return;}
            await this.$store.commit('contracts/setAllowance', {contract: contract, allowance: this.$store.state.web3.instance.utils.fromWei(response)});
            this.$eventBus.$emit('updateAllowanceAmount', {payload: contract});
        },

        getRequiredForms: async function(requiredForms) {
            if (this.session.isConnected === false) {return;}
            for(var form of requiredForms) {
                await this.$store.dispatch('forms/instantiateForm', form);
                this.$nextTick(function() {
                    this.$eventBus.$emit('updateForm', {form: form});
                });
            }
        },

        notify: function() {
            this.$notify({
                group: 'foo',
                title: 'Important message',
                text: 'Hello user! This is a notification!'
            });
        },

        acceptDisclaimer: function() {
            this.$cookies.set('disclaimer_coa_v1', true);
            document.getElementById('toggleDisclaimerModal').click();
        }
	},

	mounted () {
		this.$insProgress.finish();
        this.$store.dispatch('web3/initialise');
        if (!this.$cookies.get('disclaimer_coa_v1')) {
            document.getElementById('toggleDisclaimerModal').click();
        }
        // console.log('disclaimer: ' + this.$cookies.get('disclaimer'));
	},
}
</script>
