// initial state
const state = () => ({});

// getters
const getters = {};

// mutations
const mutations = {};

// actions
const actions = {
    async walletBalanceUpdate({ dispatch, commit, state, rootState }, contract) {return;},
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};