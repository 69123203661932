import getWeb3 from '../../utils/getWeb3';
import setWeb3Network from '../../utils/setWeb3Network';
// initial state
const state = () => ({
    isInjected: false,
    isInitialised: false,
    networkId: null,
    error: null,
    instance: null
});

// getters
const getters = {
};

// mutations
const mutations = {
    initialised(state) {
        state.isInitialised = true;
    },

    setInstance(state, instance) {
        state.instance = instance;
    },

    setNetworkId(state, id) {
        state.networkId = id;
    },

    providerName(state, providerName) {
        state.providerName = providerName;
    }
};

// actions
const actions = {
  async initialise({ commit, dispatch }, providerName = null) {
    commit("providerName", providerName);
    if (providerName !== "walletConnect") {
      getWeb3()
        .then((res) => {
          commit("setInstance", Object.freeze(res));
          commit("initialised");
          dispatch("getNetworkId");
        })
        .catch((err) => {
          console.log(err, "err!!");
        });
      try {
        await window.ethereum.enable();
      } catch (error) {
        return;
      }
    } else {
      await provider.enable();
      const web3 = new Web3(provider);
      console.log(web3);
      commit("setInstance", Object.freeze(web3));
      commit("initialised");
      dispatch("getNetworkId");
    }
  },

  async getNetworkId({ commit, state }) {
    const chainId = await state.instance.eth.net.getId();
    commit("setNetworkId", chainId);
    console.log("DEBUG CHAIN ID: " + chainId)
    return chainId;
  },

  async disconnectWallet({ commit, state }) {
    console.log("provider.disconnect");
    if (state.providerName === "walletConnect") {
      await provider.disconnect();
      console.log("suppose to be done");
    }
  },

  async switchNetwork({ commit, state }, network = null) {
    console.log("web3.switchNetwork");
    if(state.instance) {
        const currentNetwork = await state.instance.eth.net.getId();
        await setWeb3Network(state.instance, currentNetwork, network);
        commit("setNetworkId", await state.instance.eth.net.getId());
        location.reload();
    }
  },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};