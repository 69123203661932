import networks from "./networks";

const MAINNETCHAIN = 1;
const BSCCHAIN = 56;
const FANTOMCHAIN = 250;
const ARBITRUMCHAIN = 42161;
const AVALANCHECHAIN = 43114;
const OPTIMISMCHAIN = 10;

const chains = {
  [MAINNETCHAIN]: networks.MAINNET,
  [BSCCHAIN]: networks.BSC,
  [FANTOMCHAIN]: networks.FANTOM,
  [ARBITRUMCHAIN]: networks.ARBITRUM,
  [AVALANCHECHAIN]: networks.AVALANCE,
  [OPTIMISMCHAIN]: networks.OPTIMISM
}

export default chains;
