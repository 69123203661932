const networks = {
  MAINNET: {
    chainId: "0x1",
    chainName: "Ethereum",
    rpcUrls: ["https://mainnet.infura.io/v3/"],
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    icon: "/assets/img/networks/mainnet-network.jpg",
  },
  BSC: {
    chainId: "0x38",
    chainName: "Binance Smart Chain",
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    nativeCurrency: {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
    },
    icon: "/assets/img/networks/bsc-network.jpg",
  },
  FANTOM: {
    chainId: "0xfa",
    chainName: "Fantom",
    rpcUrls: ["https://rpcapi.fantom.network"],
    nativeCurrency: {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
    },
    icon: "/assets/img/networks/fantom-network.jpg",
  },
  ARBITRUM: {
    chainId: "0xA4B1",
    chainName: "Arbitrum",
    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    icon: "/assets/img/networks/arbitrum-network.jpg",
  },
  AVALANCE: {
    chainId: "0xA86A",
    chainName: "Avalanche",
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    nativeCurrency: {
      name: "Avalanche Token",
      symbol: "AVAX",
      decimals: 18,
    },
    icon: "/assets/img/networks/avalanche-network.jpg",
  },
  OPTIMISM: {
    chainId: "0xA",
    chainName: "Optimism",
    rpcUrls: ["https://mainnet.optimism.io"],
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    icon: "/assets/img/networks/optimism-network.jpg",
  },
};

export default networks;
