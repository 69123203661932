import Web3 from "web3";
import networks from "./networks";

const setWeb3Network = (web3, currentNetworkId, newNetwork) =>
  new Promise(function (resolve, reject) {
    if (currentNetworkId !== newNetwork.chainId) {
      try {
        if(newNetwork.chainId == networks["MAINNET"].chainId) {
            const res = web3.currentProvider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: Web3.utils.toHex(newNetwork.chainId) }],
            });
            resolve(res);
        }
        else {
            const request = {
                chainId: newNetwork.chainId,
                chainName: newNetwork.chainName,
                rpcUrls: newNetwork.rpcUrls,
                nativeCurrency: newNetwork.nativeCurrency
            };
            const res = web3.currentProvider.request({
              method: "wallet_addEthereumChain",
              params: [request],
            });
            resolve(res);
        }
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          alert("add this chain id");
        }

        reject(switchError);
      }
    }
  });
export default setWeb3Network;
