<script>
    import Dashboard from '../pages/Dashboard';

    const routes = [
        { path: '*', redirect: '/dashboard' },
        { path: '/dashboard', component: Dashboard, networks: [56, 97] },
    ];

    export default routes;
</script>
