import ERC20 from "./abis/erc20.json";
import IERC20 from "./abis/IERC20.json";
import ERC721 from "./abis/ERC721.json";
import PEARLZ from "./abis/pearlz.json";
import PearlzCreditSystem from "./abis/PearlzCreditSystem.json";

const getAbi = (abi) => {
  var abis = {
    ERC20: ERC20,
    IERC20: IERC20,
    ERC721: ERC721,
    PEARLZ: PEARLZ,
    PearlzCreditSystem: PearlzCreditSystem,
  };

  return abis[abi];
};

export { getAbi };
