<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-12 col-xl-9 col-xxl-7">
                <div class="panel bg-dark text-white">
                    <div class="panel-body">
                        <div class="row justify-content-center mb-3">
                            <div class="col-12 text-center">
                                <span class="text-blue fs-1 fw-bold pt-3">Connect your wallet</span>
                            </div>
                        </div>
                        <div class="row justify-content-center mb-3">
                            <div class="col-12 col-lg-6 text-center">
                                You must connect your wallet to initialise the dApp.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {}
</script>