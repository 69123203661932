<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-12 col-xl-9 col-xxl-7">
                <div class="panel bg-dark text-white">
                    <div class="panel-body">
                        <div class="row justify-content-center mb-3">
                            <div class="col-12 text-center">
                                <span class="text-blue fs-1 fw-bold pt-3">Wrong network</span>
                            </div>
                        </div>
                        <div class="row justify-content-center mb-3">
                            <div class="col-12 col-lg-6 text-center">
                                This page is not available on the current network.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {}
</script>