<template>
    <div class="navbar-item me-3 my-2">
        <button class="btn me-3" v-bind:class="{'btn-grad-purple': user == null, 'btn-grad text-dark': user !== null}" v-on:click="connectDiscord" v-if="false"><i class="fab fa-discord"></i> {{user == null ? 'Connect with discord' : user.username+'#'+user.discriminator}}</button>
        <!-- <button class="btn btn-grad text-dark" v-on:click="checkConnection">{{displayWalletAccount}}</button> -->
        <b-dropdown id="dropdown-1" class="m-md-2" split-class="btn btn-grad text-dark" toggle-class="btn btn-grad text-dark" split>
            <template #button-content><b-img style="width: 15px; height: 15px; margin-right: 6px" :src="currentNetwork.icon" rounded="circle" alt="Circle image" v-if="currentNetwork.icon !== ''"></b-img> {{displayWalletAccount}}</template>
            <b-dropdown-item-button class="text-dark" v-for="network in networks" :key="network.chainId" v-on:click="switchNetwork(network)">
                <b-img style="width: 25px; height: 25px; margin-right: 6px" :src="network.icon" rounded="circle" alt="Circle image"></b-img>
                <span class="">{{network.chainName}}</span>
            </b-dropdown-item-button>
        </b-dropdown>
        <button id="walletConnectModalToggle" class="d-none" data-bs-toggle="modal" data-bs-target="#walletConnectModal">Toggle Modal</button>
    </div>
</template>

<style>
    .dropdown-menu {
        background: linear-gradient(to right top, #afc9dc 0%, #3a638e 65%, #3a638e 100%);
    }
    .dropdown-item, .dropdown-item:active {
        background-color: transparent;
        color: black;
    }
    .dropdown-item:hover {
        background-color: white;
        color: black;
    }
    
</style>

<script>
    const axios = require('axios').default;
    import {mapState} from 'vuex';
    import networks from '../../utils/networks';
    import chains from '../../utils/chains';

    export default {
        name: 'WalletConnect',
        components: {
        },
        data() {
            return {
                requiredContracts: ["HORDE", "BUSD", "NodeManager", "ZOMBIE", "Consolidator"],
                loaded: {
                    contracts: []
                },
                token: this.$cookies.get('discord-token'),
                user: null,
                redirect: window.location.href.split('?')[0],
                networks: networks,
                chains: chains,
                currentNetwork: {
                    icon: ""
                },
            }
        },

        created() {
            this.$eventBus.$on("contracts/instantiateContract", (event) => {
                if (!this.requiredContracts.includes(event.payload)) {return;}
                if (event.payload === "NodeManager") {
                    this.loaded.contracts.push(event.payload);
                    if (this.loaded.contracts.includes('Consolidator')) {
                        this.registerUser();
                    }
                    return;
                }
                if (event.payload === "Consolidator") {
                    this.loaded.contracts.push(event.payload);
                    if (this.loaded.contracts.includes('NodeManager')) {
                        this.registerUser();
                    }
                    return;
                }
            });

            this.$eventBus.$on("web3/switchNetwork", (event) => {
                console.log("DEBUG: SWITCH NETWORK EVENT HAS FIRED!");
                console.log(event.payload);
                this.currentNetwork = event.payload
            });

            this.$eventBus.$on("web3/initialise", async (event) => {
                const networkId = await this.$store.dispatch('web3/getNetworkId');
                const network = chains[networkId];
                if(network) {
                    this.currentNetwork = network;
                }
            })

        },

        updated () {
        },

        computed: {
            displayWalletAccount: function() {
                if (this.session.isConnected === false || this.wallet.account === undefined) {return 'Connect your wallet';}
                return this.wallet.account.substring(0, 12) + '...' + this.wallet.account.substring(this.wallet.account.length - 4);
                // return 'Disconnect your wallet';
            },
            ...mapState(['contracts', 'session', 'wallet', 'web3'])
        },

        watch: {
            "session.isConnected": function(newVal, oldVal) {
                if (newVal === true && oldVal === false) {this.registerUser();}
            }
        },

        methods: {
            checkConnection: function() {
                if (this.session.isConnected === true) {
                    this.$root.$children[0].disconnectWallet();
                    return;
                }
                document.getElementById('walletConnectModalToggle').click();
                // this.$root.$children[0].connectWallet();return;
            },

            connectWithProvider: async function(providerName) {
                this.$root.$children[0].connectWallet(providerName);
            },

            initDiscord: async function() {
                if (this.token !== null) {
                    this.consumeToken();
                    return;
                }
                const fragment = new URLSearchParams(window.location.search);
                const [code] = [fragment.get('code')];
                if (!code) {
                    this.user = null;
                    return;
                }

                let response = await axios.post("https://horde.games/api/discord/code", {
                    code: code,
                    redirect: this.redirect,
                });

                if (response.data.error !== undefined) {
                    this.handleDiscordError();
                    return;
                }
                this.$cookies.set('discord-token', response.data);
                this.token = response.data;
                this.consumeToken();
            },

            handleDiscordError: function() {
                this.$cookies.remove('discord-token');
                this.token = null;
                this.user = null;
                window.location.search = '';
            },

            consumeToken: async function() {
                let response = await axios.get("https://discordapp.com/api/users/@me", {
                    headers: {Authorization: `Bearer ${this.token.access_token}`}
                });

                this.user = response.data;
                this.registerUser();
            },

            registerUser: async function() {
                if (this.user === null) {return;}
                if (!this.loaded.contracts.includes('NodeManager')) {return;}
                if (!this.loaded.contracts.includes('Consolidator')) {return;}
                if (this.web3.networkId !== 56) {return;}
                // console.log(this.web3.networkId);
                
                let nodes = JSON.parse(JSON.stringify(await this.contracts.NodeManager.instance.methods.getNodesOf(this.wallet.account).call()));
                let havePlot = 0;
                let haveStone = 0;
                let haveRune = 0;
                for(let node of nodes) {
                    if (node[4] === "0") {havePlot = 1;}
                    if (node[4] === "2") {haveStone = 1;}
                    if (node[4] === "1") {haveRune = 1;}
                }

                let vault = Number(this.web3.instance.utils.fromWei(await this.contracts.Consolidator.instance.methods.vaultAmount(this.wallet.account).call()));
                let exit = Number(this.web3.instance.utils.fromWei(await this.contracts.Consolidator.instance.methods.busdAmount(this.wallet.account).call()));
                let ratio = 0;
                if (vault > 0  && exit === 0) {ratio = 100;}
                if (vault > 0  && exit > 0) {
                    let total = Number(vault) + Number(exit);
                    ratio = Number(vault) / Number(total) * 100;
                }

                axios.post("https://horde.games/api/discord/register", {
                    user: this.user,
                    isPlotOwner: await this.contracts.NodeManager.instance.methods.isNodeOwner(this.wallet.account).call(),
                    havePlot: havePlot,
                    haveStone: haveStone,
                    haveRune: haveRune,
                    exit: exit,
                    vault: vault,
                    ratio: ratio,
                });
            },

            connectDiscord: function () {
                window.location = 'https://discord.com/api/oauth2/authorize?client_id=961385220647567380&redirect_uri='+ encodeURIComponent(this.redirect) +'&response_type=code&scope=identify';
            },

            switchNetwork: function (network) {
                console.log("DEBUG: UPDATING NETWORK");
                console.log(network);
                this.$root.$children[0].switchNetwork(network);
            },
        },

        mounted() {
            this.initDiscord();
        }
    }
</script>